.searchBar {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 20px;
}

.inputContainer {
    position: relative;
    flex: 1;
    margin-right: 80px;
}

.input {
    width: 100%;
    padding: 12px 40px 12px 12px; /* Espacio para la X */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.clearButton {
    position: absolute;
    right: -55px;
    top: 50%;
    transform: translateY(-57%);
    background: none;
    border: none;
    color: #aaa;
    font-size: 20px;
    cursor: pointer;
}

.clearButton:hover {
    color: #333;
}

.searchButton {
    background-color: #c5763d;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.searchButton:hover {
    background-color: #e09056;
}

.searchButton i {
    margin-right: 8px;
}
