.footerContainer{
    border-color: 1px solid red; 
    background-color: azure;
}

.footer {
    border-color: 1px solid red; 
    padding: 0px;
    text-align: center;
    }
