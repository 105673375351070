.navContainer {
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid black;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    width: 200px;
}

.navLinks {
    display: flex;
    align-items: center;
}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.link {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid black;
    border-radius: 10px;
    margin-right: 15px;
}

.link:last-child {
    margin-right: 0;
}

.link:hover {
    background-color: #d4d4d4;
}

.searchBar {
    display: flex;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
}

.input {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 16px;
}

.searchButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.searchButton:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navLinks {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 90px;
        left: 0;
        background-color: rgb(255, 255, 255);
        z-index: 1;
        border-top: 1px solid black;
    }

    .navLinks.showMenu {
        display: flex;
    }

    .hamburger {
        display: block;
    }

    .link {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        border: none;
        margin: 0;
    }

    .link:hover {
        background-color: #d4d4d4;
    }
}
