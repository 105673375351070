/* ProductForm.module.css */

.container {
    margin-right: 1000PX;
    margin-left: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;

  }
  .formBoxContainer {
    flex: 1; /* Ocupa todo el espacio disponible en la izquierda */
    margin-right: 20px; /* Ajusta el margen derecho entre el formulario y la imagen */
  }
  
  .title {
    text-align: center;
    color: #333;
  }
  
  form {
   text-align: left;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  button {
    background-color: #D3D3E7;
    color: #000000;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #a8a8d4;
  }
  
  .buttonback {
    background-color: #D3D3E7;
    color: #333;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .buttonback:hover {
    background-color: #a8a8d4;
  }

  .img {
    width: 600px; /* Ajusta el tamaño de la imagen según tus preferencias */
    border-radius: 8px;
    margin: 0; /* Elimina los márgenes externos */
    position: absolute; /* Cambia la posición a absoluta */
    top: 40%;
    left: 75%; /* Coloca el título en el 50% del ancho del contenedor */
    transform: translateX(-50%); /* Centra el título */
  }
  
  @media (max-width: 768px) {
    .container {
        margin: 10px;
        padding: 15px;
    }

    .formBoxContainer {
        margin-right: 0;
    }

    .img {
        display: none;
    }

    input {
        margin-bottom: 10px;
    }

    button, .buttonback {
        width: 100%;
        margin-top: 10px;
    }
}