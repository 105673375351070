.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #fff;
  }

  .imgContainer {
    margin-bottom: 20px; /* Espacio entre la imagen y la tarjeta */
    justify-content: center;
    align-items:center;
  }

  .img {
    display: block; /* Asegura que la imagen se comporte como un bloque */
    margin-left: auto; /* Centra la imagen horizontalmente */
    margin-right: auto; /* Centra la imagen horizontalmente */
    width: 20%; /* Ancho de la imagen */
    height: auto; /* Altura automática para mantener la relación de aspecto */
    margin-bottom: 5%; /* Espacio entre la imagen y el contenido debajo */
    margin-top: 0%; /* Espacio entre la imagen y el contenido arriba */
    }
  
    .card {
        width: 450px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .content {
        margin-bottom: 20px;
        padding-top: 1px;
        padding-left: 10%;
        padding-right: 10%;
      }
      
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
      }
      
      .formGroup {
        margin-bottom: 20px;
        display: flex;
      }
      
      .error {
        color: #ff0000;
        margin-bottom: 10px;
      }
      
      .form {
        width: 90%; /* Ancho del formulario */
        margin: auto; /* Centrar el formulario */
      }
      
      label {
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .inputForm {
        width: 100%;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box; /* Incluye borde y relleno en el ancho especificado */
        margin: 0;
      }
      
      .passwordGroup {
          display: flex;
        }
        
        .passwordIcon {
          display: flex;
          align-items: center;
          padding: 0 16px;
          cursor: pointer;
        }
      
      .buttonSubmit {
        width: 100%; /* Ancho del botón igual al del contenedor del formulario */
        margin-top: 5%; /* Espacio entre el botón y los inputs */
        padding: 15px;
        background-color: #c5763d ;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      .buttonSubmit:hover{
        background-color: #b66d3a ;
      }
      
      .forgotPassword {
        text-align: center;
        color: rgb(31, 30, 30);
        text-decoration: none;
      }
      
      .forgotPasswordLink {
        color: rgb(31, 30, 30);
        text-decoration: none;
        font-size: 13px;
      }