.header {
    display: flex;
    align-items: center;
    gap: 10px;
   margin-right: 35px;
  }
  
  .image {
    width: 50px; /* Ajusta el tamaño de la imagen */
    height: 50px;
    border-radius: 50%; /* Redondea la imagen */
  }
  
  .details {
    display: flex; /* Configura la estructura de la cuadrícula */
    flex: 1;
    grid-template-columns: 1fr 3fr; /* Define las columnas: título y valor */
    gap: 5px; /* Espacio entre las filas */
  }
  
  .row {
    display: flex;
  
    align-items: center;
    justify-content: space-between; /* Alinea el texto a ambos extremos */
    width: 100%; 
    padding-top: 6px;
  }
  
  .column {
    font-weight: bold; /* Destaca las columnas (títulos) */
  }
 
  .redText {
    color: red;
  }

  .scrollButton {
    position: fixed;
    bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: #007bff81;
    color: white;
    cursor: pointer;
    z-index: 1000;
  }
  
  .scrollButton:hover {
    background-color: #0056b3;
  }
/* Media queries para pantallas más pequeñas */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    margin-right: 0;
    gap: 5px;
    text-align: center;
  }

  .details {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    gap: 10px;
    text-align: center;
  }

  .row {
    flex-direction: column; /* Alinea las filas en columna */
  }
  .scrollButton {
    width: 30px;
       }
}
  @media (max-width: 480px) {
    .image {
      width: 40px; /* Ajusta el tamaño de la imagen */
      height: 40px;
    }
  
    .header h2 {
      font-size: 1rem; /* Ajusta el tamaño de la fuente */
    }
  
    .row p {
      font-size: 0.875rem; /* Ajusta el tamaño de la fuente */
    }
    .scrollButton {
      width: 30px;
         }
  }