.card{
    background-color: #00000010;
    width: 300px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    border: 1px solid black;
}
.card:hover {
    transform: scale(1.01);
    
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0;
    background-color: white;
  }
  
  .link {
    text-decoration: none;
    color: black;
    display: flex;
    width: 100%; 
  
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
   margin-right: 35px;
  }
  
  .image {
    width: 50px; /* Ajusta el tamaño de la imagen */
    height: 50px;
    border-radius: 50%; /* Redondea la imagen */
  }
  
  .details {
    display: flex; /* Configura la estructura de la cuadrícula */
    flex: 1;
    grid-template-columns: 1fr 3fr; /* Define las columnas: título y valor */
    gap: 5px; /* Espacio entre las filas */
  }
  
  .row {
    display: flex;
  
    align-items: center;
    justify-content: space-between; /* Alinea el texto a ambos extremos */
    width: 100%; 
    padding-top: 6px;
  }
  
  .column {
    font-weight: bold; /* Destaca las columnas (títulos) */
  }
  
  .row p{
    
  }

  @media (max-width: 768px) {
    .card {
      flex-direction: column; /* Cambia a columna para pantallas pequeñas */
      align-items: center; /* Alinea elementos al centro */
    }
  
    .header {
      margin-right: 0; /* Elimina el margen derecho */
      flex-direction: column; /* Cambia a columna */
      align-items: center; /* Centra los elementos */
    }
  
    .details {
      flex-direction: column; /* Cambia a columna */
      align-items: center; /* Centra los elementos */
    }
  
    .row {
      justify-content: center; /* Centra el texto */
      padding-top: 10px;
    }
  
    .image {
      width: 70px; /* Ajusta el tamaño de la imagen */
      height: 70px;
    }
  }