.filtersContainer {
    display: flex;
    margin-bottom: 20px;
   margin-top: 10px;
  }
  
  .filterItem {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .select {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .buttonContainer {
  
    padding: 5px;
    justify-content: center;
  }
  
  .button {
    margin: 0 10px;
    padding: 1px 30px;
    background-color: #c5763d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px; /* Margen izquierdo entre las imágenes */
    margin-top: 20px;
  }
  
  .button:hover {
    background-color: #ee9250;
  }
  

  .container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
    text-align: center; /* Centra el texto horizontalmente */
  }

  
  @media (max-width: 768px) {
    .filtersContainer {
      flex-direction: column; /* Cambia a columna para pantallas pequeñas */
      align-items: center; /* Centra los elementos */
    }
  
    .select {
      width: 100%; /* Ajusta el ancho del selector al 100% */
      max-width: 300px; /* Añade un ancho máximo para evitar que sea demasiado grande */
      margin-bottom: 10px; /* Añade espacio entre los selectores */
    }
  
    .buttonContainer {
      display: flex;
      flex-direction: column; /* Cambia a columna para pantallas pequeñas */
      align-items: center; /* Centra los botones */
    }
  
    .button {
      width: 100%; /* Ajusta el ancho del botón al 100% */
      max-width: 300px; /* Añade un ancho máximo para evitar que sea demasiado grande */
      margin: 10px 0; /* Añade espacio entre los botones */
    }
  }